import {
  CellId,
  DataBrowserTab,
  DataConnectionId,
  DataSourceSchemaId,
  DataSourceTableId,
  EnumValues,
  HexId,
  // eslint-disable-next-line tree-shaking/no-side-effects-in-initialization -- no side effects
  typedObjectValues,
} from "@hex/common";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { CellInsertType } from "../../components/cell/types.js";

export const DataBrowserSearchResultType = {
  DATAFRAME: "Dataframe",
  TABLE: "Table",
  SCHEMA: "Schema",
  DATABASE: "Database",
} as const;

export type DataBrowserSearchResultType = EnumValues<
  typeof DataBrowserSearchResultType
>;
export const DefaultSearchResultTypes = typedObjectValues(
  DataBrowserSearchResultType,
);

export type DataBrowserState = {
  magicAllowed: boolean;
  dataManagerDataConnectionId: DataConnectionId | null;
  hexId?: string;
  insertType?: CellInsertType;
  targetCellId?: CellId;
  [DataBrowserTab.RECENTLY_USED]: {
    dataBrowserDataConnectionId: DataConnectionId | null;
    dataBrowserTableId: DataSourceTableId | null;
  };
  [DataBrowserTab.FAVORITES]: {
    dataBrowserDataConnectionId: DataConnectionId | null;
    dataBrowserTableId: DataSourceTableId | null;
    dataBrowserSchemaId: DataSourceSchemaId | null;
    displayObjectType: "table" | "schema";
  };
};

const initialDataBrowserState: DataBrowserState = {
  magicAllowed: false,
  dataManagerDataConnectionId: null,
  [DataBrowserTab.RECENTLY_USED]: {
    dataBrowserDataConnectionId: null,
    dataBrowserTableId: null,
  },
  [DataBrowserTab.FAVORITES]: {
    dataBrowserDataConnectionId: null,
    dataBrowserTableId: null,
    dataBrowserSchemaId: null,
    displayObjectType: "table",
  },
};

type SetOrgMagicAllowed = {
  magicAllowed: boolean;
};

type SetDataConnectionId = {
  dataConnectionId: DataConnectionId | null;
};

type SetTable = {
  tab: Exclude<DataBrowserTab, "DATA">;
  tableId: DataSourceTableId | null;
  dataConnectionId: DataConnectionId | null;
};

type SetSchema = {
  schemaId: DataSourceSchemaId | null;
};

type SetFavoritesDisplayObjectType = {
  displayObjectType: "table" | "schema";
};

type SetInsertInfoPayload = {
  insertType: CellInsertType;
  targetCellId?: CellId;
};

const dataBrowserSlice = createSlice({
  name: "dataBrowser",
  initialState: initialDataBrowserState,
  reducers: {
    setMagicAllowedForDataBrowser: (
      state,
      action: PayloadAction<SetOrgMagicAllowed>,
    ) => {
      state.magicAllowed = action.payload.magicAllowed;
    },
    setDataConnectionIdForDataManager: (
      state,
      action: PayloadAction<SetDataConnectionId>,
    ) => {
      state.dataManagerDataConnectionId = action.payload.dataConnectionId;
    },
    setTableForDataBrowser: (state, action: PayloadAction<SetTable>) => {
      const { dataConnectionId, tab, tableId } = action.payload;
      if (tab === DataBrowserTab.RECENTLY_USED) {
        state[DataBrowserTab.RECENTLY_USED].dataBrowserTableId = tableId;
        state[DataBrowserTab.RECENTLY_USED].dataBrowserDataConnectionId =
          dataConnectionId;
      } else {
        state[DataBrowserTab.FAVORITES].dataBrowserTableId = tableId;
        state[DataBrowserTab.FAVORITES].dataBrowserDataConnectionId =
          dataConnectionId;
      }
    },
    setFavoritesDisplayObjectType: (
      state,
      action: PayloadAction<SetFavoritesDisplayObjectType>,
    ) => {
      state[DataBrowserTab.FAVORITES].displayObjectType =
        action.payload.displayObjectType;
    },
    // currently only applicable to favorites tab
    setFavoritesSelectedSchema: (state, action: PayloadAction<SetSchema>) => {
      state[DataBrowserTab.FAVORITES].dataBrowserSchemaId =
        action.payload.schemaId;
    },
    /**
     * If the current hexId is non-null and different than the provided hexId,
     * we will also reset the state
     */
    setDataBrowserHexId: (state, action: PayloadAction<HexId | null>) => {
      if (state.hexId === action.payload) {
        return;
      }
      // state can be project specific, so if the previous hexId corresponds to
      // a different project, we reset it.
      if (state.hexId != null) {
        state[DataBrowserTab.FAVORITES] =
          initialDataBrowserState[DataBrowserTab.FAVORITES];
        state[DataBrowserTab.RECENTLY_USED] =
          initialDataBrowserState[DataBrowserTab.RECENTLY_USED];
      }
      state.hexId = action.payload ?? undefined;
    },
    setCellInsertInfo: (state, action: PayloadAction<SetInsertInfoPayload>) => {
      const { insertType, targetCellId } = action.payload;

      state.insertType = insertType;
      state.targetCellId = targetCellId;
    },
  },
});

export const dataBrowserTreeReducer = dataBrowserSlice.reducer;

export const {
  setCellInsertInfo,
  setDataBrowserHexId,
  setDataConnectionIdForDataManager,
  setFavoritesDisplayObjectType,
  setFavoritesSelectedSchema: setSchemaForDataBrowser,
  setMagicAllowedForDataBrowser,
  setTableForDataBrowser,
} = dataBrowserSlice.actions;
