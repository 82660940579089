import {
  FRACTIONAL_INDEX_END,
  FRACTIONAL_INDEX_START,
  HexVersionId,
  fractionalIndexMidpoint95,
  initialMidpoint95,
} from "@hex/common";

import { RootState } from "../../store.js";
import { hexVersionMPSelectors } from "../hexVersionMPSlice.js";
import { cellSelectionSelectors } from "../logicViewSlice.js";

import {
  CellLocation,
  ChildCellLocation,
  GlobalCellLocation,
  RelativeCellLocation,
  ResolvedCellLocation,
} from "./CellLocation.js";

/**
 * Converts a declarative {@link CellLocation} to
 * a concrete {@link ResolvedCellLocation} so that an
 * insert / move can actually occur or render something
 * else at the potential insert location.
 */
export function resolveCellLocation({
  hexVersionId,
  location,
  state,
}: {
  hexVersionId: HexVersionId;
  location: CellLocation;
  state: RootState;
}): ResolvedCellLocation {
  if (location.type === "global") {
    return resolveGlobalCellLocation({ hexVersionId, location, state });
  } else if (location.type === "child") {
    return resolveChildCellLocation({ hexVersionId, location, state });
  } else {
    return resolveRelativeCellLocation({ hexVersionId, location, state });
  }
}

function resolveRelativeCellLocation({
  hexVersionId,
  location,
  state,
}: {
  hexVersionId: HexVersionId;
  location: RelativeCellLocation;
  state: RootState;
}): ResolvedCellLocation {
  const targetCell = hexVersionMPSelectors
    .getCellSelectors(hexVersionId)
    .selectById(state, location.targetCellId);

  if (targetCell == null) {
    throw new Error(`Unable to find target cell ${location.targetCellId}`);
  }

  const parentCellId = targetCell.parentCellId ?? null;

  const parentCell =
    parentCellId != null
      ? hexVersionMPSelectors
          .getCellSelectors(hexVersionId)
          .selectById(state, parentCellId)
      : undefined;

  // Trying to insert into a component cell is usually a mistake
  // so try to insert one level up instead.
  // If we are confident we are always using this utility correctly,
  // we can possibly remove this check to allow for describing more
  // cell positions.
  if (parentCell?.cellType === "COMPONENT_IMPORT") {
    return resolveCellLocation({
      state,
      hexVersionId,
      location: {
        type: "relative",
        targetCellId: parentCell.id,
        position: location.position,
      },
    });
  }

  const sortedCells = hexVersionMPSelectors
    .getCellSelectors(hexVersionId)
    .selectFlattenedSortedV2(state);

  const siblingCells = sortedCells.filter(
    (c) =>
      c.parentCellId === parentCellId ||
      (parentCellId == null && c.parentCellId == null),
  );
  const targetCellIndex = siblingCells.findIndex((c) => c.id === targetCell.id);

  if (targetCellIndex === -1) {
    throw new Error(
      `Unable to find target cell ${location.targetCellId} in sibling cells`,
    );
  }

  const order =
    location.position === "before"
      ? fractionalIndexMidpoint95(
          siblingCells[targetCellIndex - 1]?.order ?? FRACTIONAL_INDEX_START,
          targetCell.order,
        )
      : fractionalIndexMidpoint95(
          targetCell.order,
          siblingCells[targetCellIndex + 1]?.order ?? FRACTIONAL_INDEX_END,
        );

  return {
    order,
    parentCellId,
    parentBlockCellId: parentCell?.blockCellId ?? null,
    parentComponentImportCellId: null,
  };
}

function resolveChildCellLocation({
  hexVersionId,
  location,
  state,
}: {
  hexVersionId: HexVersionId;
  location: ChildCellLocation;
  state: RootState;
}): ResolvedCellLocation {
  const parentCell =
    location.parentCellId != null
      ? hexVersionMPSelectors
          .getCellSelectors(hexVersionId)
          .selectById(state, location.parentCellId)
      : undefined;

  // Trying to insert into a component cell is usually a mistake
  // so try to insert one level up instead
  if (parentCell?.cellType === "COMPONENT_IMPORT") {
    return resolveCellLocation({
      state,
      hexVersionId,
      location: {
        type: "child",
        parentCellId: parentCell.parentCellId,
        position: location.position,
      },
    });
  }

  const sortedCells = hexVersionMPSelectors
    .getCellSelectors(hexVersionId)
    .selectFlattenedSortedV2(state);
  const siblingCells = sortedCells.filter(
    (c) =>
      c.parentCellId === location.parentCellId ||
      (location.parentCellId == null && c.parentCellId == null),
  );

  if (siblingCells.length === 0) {
    return {
      order: initialMidpoint95,
      parentCellId: location.parentCellId,
      parentBlockCellId: parentCell?.blockCellId ?? null,
      parentComponentImportCellId: null,
    };
  } else if (location.position === "first") {
    return resolveCellLocation({
      state,
      hexVersionId,
      location: {
        type: "relative",
        targetCellId: siblingCells[0].id,
        position: "before",
      },
    });
  } else {
    return resolveCellLocation({
      state,
      hexVersionId,
      location: {
        type: "relative",
        targetCellId: siblingCells[siblingCells.length - 1].id,
        position: "after",
      },
    });
  }
}

function resolveGlobalCellLocation({
  hexVersionId,
  state,
}: {
  hexVersionId: HexVersionId;
  location: GlobalCellLocation;
  state: RootState;
}): ResolvedCellLocation {
  const firstSelectedCellId = cellSelectionSelectors.selectSortedSelectedCells(
    state,
    hexVersionId,
  )[0]?.id;

  if (firstSelectedCellId != null) {
    return resolveCellLocation({
      state,
      hexVersionId,
      location: {
        type: "relative",
        targetCellId: firstSelectedCellId,
        position: "after",
      },
    });
  } else {
    return resolveCellLocation({
      state,
      hexVersionId,
      location: {
        type: "child",
        parentCellId: null,
        position: "last",
      },
    });
  }
}
